import React from 'react';

export const MainFooter = () => (
	<footer className="footer">
		<div className="container">
			<a className="footer__logo" href="/" title="MXDC"><img src="/static/images/logo.svg" alt="MXDC" /></a>
			<nav className="footer__menu">
				<a href="/" className="footer__menu__link footer__menu__link--privacy">Privacy</a>
				<a href="/" className="footer__menu__link footer__menu__link--termsandconditions">Terms and Conditions</a>
				<a href="/" className="footer__menu__link footer__menu__link--frequentlyaskedquestions">Frequently Asked Questions</a>
				<a href="/" className="footer__menu__link footer__menu__link--contactus">Contact Us</a>
			</nav>
		</div>
	</footer>
) 