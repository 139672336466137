import React from 'react';

export class MainHeader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			'menuOpen' 	: false
		}

		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	openMenu() {
		this.setState({'menuOpen' : true});
	}

	closeMenu() {
		this.setState({'menuOpen' : false});
	}

	render() {
		return (
			<header className={'header ' + ((this.state.menuOpen) ? 'header--menu-open' : 'header--menu-closed')}>
				<div className="container">
					<a className="header__logo" href="/" title="MXDC"><img src="/static/images/logo.svg" alt="MXDC" /></a>
					<span className="menu_open" onClick={this.openMenu}><img src="/static/images/menu_icon.svg" alt="Open Menu" /></span>
					<span className="menu_close" onClick={this.closeMenu}><img src="/static/images/close_icon.svg" alt="Close Menu" /></span>
					<nav className="header__menu">
						<div className="header__menu__inner">
							<a href="/" className="header__menu__link header__menu__link--program">Program</a>
							<a href="/" className="header__menu__link header__menu__link--partners">Partners</a>
							<a href="/" className="header__menu__link header__menu__link--locations">Locations</a>
							<a href="/" className="header__menu__link header__menu__link--aboutmxdc">About MXDC</a>
							<a href="/" className="header__menu__link header__menu__link--contactus">Contact Us</a>
						</div>
					</nav>
				</div>
			</header>
		);
	}
	
}