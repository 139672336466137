import React from 'react';
import API from '../libraries/Api.jsx';
import {BackupData} from '../libraries/Data.jsx';

export class Events extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			'requestState' 	: 'default',
			'events' 		: false
		}
	}

	componentDidMount() {
		this.getEvents();
	}

	getEvents() {
		API.get('https://s3-ap-southeast-2.amazonaws.com/studiobravo/FED+Project/events.json')
		.then(
			function(result) {
				if (result.data) {
					this.setState({ 
						'requestStatus' : 'complete',
						'events' : result.data,
					});	
				}
				else {
					this.setState({ 'requestStatus' : 'complete' });
				}
			}.bind(this)
		)
		.catch(
			function (error) {
				this.allowSubmit = true;
				if (error.response) {
					//The request was made and the server responded with a status code
					//that falls out of the range of 2xx
					console.log('not 200');
					//console.log(error.response.data);
					//console.log(error.response.status);
					//console.log(error.response.headers);
				} else if (error.request) {
					//The request was made but no response was received
					//`error.request` is an instance of XMLHttpRequest in the browser and an instance of
					//http.ClientRequest in node.js
					console.log('no response');
					//console.log(error.request);
				} else {
					//Something happened in setting up the request that triggered an Error
					console.log('other');
					//console.log('Error', error.message);
				}
				////console.log(error.config);
				this.setState({ 
					'requestStatus' : 'complete',
					'events' : BackupData,
				});	
			}.bind(this)
		);

		this.setState({'requestStatus' : 'inprogress'});
	}

	render() {
		return(
			<div className="events">
				<div className="container">
					<div className="row">

						{(this.state.requestStatus === 'complete' && this.state.events && this.state.events.length) 
							? 
							this.state.events.map(
								(eventItem, index) => {
									return (
										<EventItem key={index} data={eventItem} />
									)
								}
							)
							: 
							<p>There are no Events to display.</p>
						}

					</div>
				</div>

			</div>
		);
	}
}



const EventItem = (props) => (

<article className="events__event col-xs-60 col-md-30 col-lg-20">
	<div className="events__event__inner">
		<figure>
			{(props.data.imgUrlMobile) && <img className="event__image event__image--mobile" src={props.data.imgUrlMobile} alt={props.data.title} />}
			{(props.data.imgUrlMobile2x) && <img className="event__image event__image--mobile2x" src={props.data.imgUrlMobile2x} alt={props.data.title} />}
			{(props.data.imgUrlDesktop) && <img className="event__image event__image--desktop" src={props.data.imgUrlDesktop} alt={props.data.title} />}
			{(props.data.imgUrlDesktop2x) && <img className="event__image event__image--desktop2x" src={props.data.imgUrlDesktop2x} alt={props.data.title} />}
		</figure>
		<summary>
			<h1 className="events__event__title">{props.data.title}</h1>
			<p className="events__event__description">{props.data.description}</p>
		</summary>
	</div>
</article>

);


