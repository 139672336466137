import axios from 'axios';


export default axios.create({
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/x-www-form-urlencoded',
		// 'Content-Type': 'application/json;charset=UTF-8',
		'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
	},
	withCredentials: false,
	transformRequest: [function (data, headers) {
		return querystringify(data);
	}],
});


function querystringify(obj, prefix) {
	var has = Object.prototype.hasOwnProperty;

	prefix = prefix || '';

	var pairs = [];

	//
	// Optionally prefix with a '?' if needed
	//
	if ('string' !== typeof prefix) prefix = '?';

	for (var key in obj) {
		if (has.call(obj, key)) {
			//console.log(obj[key]);
			if (obj[key] != null && obj[key].constructor === Array) {
				for (var subKey in obj[key]) {
					pairs.push(encodeURIComponent(key + '[' + subKey + ']') +'='+ encodeURIComponent(obj[key][subKey]));
				}
			}
			else {
				pairs.push(encodeURIComponent(key) +'='+ encodeURIComponent(obj[key]));
			}
		}
	}

	return pairs.length ? prefix + pairs.join('&') : '';
}