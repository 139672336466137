import React from 'react';

import {MainHeader} from './components/Header.jsx';
import {Hero} from './components/Hero.jsx';
import {Events} from './components/Events.jsx';
import {MainFooter} from './components/Footer.jsx';

function App() {
  return (
    <div className="App">
      <MainHeader />
      <main className="main">
        <Hero />
        <Events />
      </main>
      <MainFooter />
    </div>
  );
}

export default App;
