import React from 'react';

export const Hero = () => (
	<div className="hero">
		<div className="hero__caption container">
			<div className="hero__caption__inner">
				<h1 className="hero__caption__title">MXDC Melbourne</h1>
				<p className="hero__caption__description">June 20 &ndash; 27</p>
			</div>
		</div>
	</div>
)